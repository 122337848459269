import React, { useEffect } from 'react';
import Hero from '../components/sections/Hero';
import  API from '../Api';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import PagedHomePage from '../components/sections/PagedHomePage';
import Header from '../components/layout/Header';
import HeaderLoggedIn from '../components/layout/HeaderLoggedIn';
import HeaderOnboarding from '../components/layout/HeaderOnboarding';
import HeaderInfluencer from '../components/layout/HeaderInfluencer';
import ReactGA from "react-ga4";

const Home = ({loggedIn, onboarded, influencer, loggedInCallBack, loggedOutCallBack, userLoggedIn}) => {

ReactGA.initialize("G-WQP2CKSW84");
//ReactGA.send({ hitType: "pageview", page: "/home_page", title: "home_page" });

return (
    <>
    <meta name="keywords" content="news feed, ideas, influencers, predictions" />
    <meta name="description" content="Main news feed" />
{ loggedIn && onboarded && influencer && <HeaderInfluencer navPosition="right" className="reveal-from-bottom" /> }
{ loggedIn && onboarded && !influencer && <HeaderLoggedIn navPosition="right" className="reveal-from-bottom" /> }
{ !loggedIn && !onboarded && <Header navPosition="right" className="reveal-from-bottom" /> }
{ loggedIn && !onboarded && <HeaderOnboarding navPosition="right" className="reveal-from-bottom" /> }
    <main className="site-content">
      <Hero className="illustration-section-01" loggedOutCallBack={loggedOutCallBack} loggedIn={loggedIn} loggedInCallBack={loggedInCallBack}/>
      { !loggedIn && <FeaturesTiles /> }
      {/*<FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />*/}
      <PagedHomePage topDivider loggedIn={loggedIn}/>
</main>
    </>
  );
}

export default Home;


