import * as React from 'react';
import  API from '../Api';
import Header from '../components/layout/HeaderLoggedIn';
import HeaderInfluencer from '../components/layout/HeaderInfluencer';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ReactGA from "react-ga4";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const orderTypes = [
  {
    value: 'buy',
    label: 'BUY',
  },
  {
    value: 'sell',
    label: 'SELL',
  },
]


const OrderForm = ({email, influencer, currencySymbol}) => {
  const [ticker, setTicker] = React.useState('AAPL');
  const [tickersState, setTickersState] = React.useState('');
  const [orderType, setOrderType] = React.useState('buy');
  const [number, setNumber] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [submitted, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [priceError, setPriceError] = React.useState(false);
  const [disableButton, setDisableButton] = React.useState(true);
  const [isLoading, setLoading] = React.useState(true);
  const [title, setTitle] = React.useState('Apple Inc.');
  const [link, setLink] = React.useState('https://finance.yahoo.com/quote/AAPL/');
  const [currency, setCurrency] = React.useState(null);
  const [ownedAssets, setOwnedAssets] = React.useState(null);
  const [showAssets, setShowAssets] = React.useState(true);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTicker(event.target.value);
    setTitle(tickersState[event.target.selectedIndex]['title']);
    setLink(tickersState[event.target.selectedIndex]['link']);
  };

  ReactGA.initialize("G-WQP2CKSW84");

  const handleLink = () => {
  window.open(link);
};


const getPortfolio = () => {
API({
    url: '/portfolio'
  })
  .then(response => {
    if (response.data['currency'] != null){
      console.log(response.data['currency'][response.data['currency'].length - 1]);
      setCurrency(response.data['currency'][response.data['currency'].length - 1]);
    }
  });
  API({
    url: '/profile'
    })
    .then(response => {
    if (response.statusText === "OK"){
    if (response.data['localytdStats'] != null){
      setOwnedAssets(response.data['assets'] );
    }else{
      setOwnedAssets(response.data['assets'] );
    }
    }}).catch(response =>{
    });


}

React.useEffect(() => {
  if(isLoading === false)
	return;
    API({
    url: '/ticker'
  })
  .then(response => {
    if (response.statusText === "OK"){
    var options = "";

    for(const ticker of response.data){
        if(ticker['mappedTicker'] === "VIX")
            continue;
        options += '{"value": "' + ticker['mappedTicker'] + '" ,"label" : "' + ticker['mappedTicker'] + '", "title" : "' + ticker['title'] +'", "link" : "' + ticker['link'] +'"},';
    }
    options = "[" + options.slice(0, -1)+ "]";
    setTickersState(JSON.parse(options));
    getPortfolio();
    setLoading(false);
    }
  }).catch(response =>{
  });
  });

const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  if(event.target.value === ""){
    return;
  }
  API({
    url: '/ticker/suggest/' +event.target.value
  })
  .then(response => {
    if (response.statusText === "OK"){
      if(response.data.length ==0){
        setTitle("No asset found")
        setTicker("");
        setShowAssets(false);
        setDisableButton(true);
      }else{
    var options = "";

    for(const ticker of response.data){
        if(ticker['mappedTicker'] === "VIX")
            continue;
        options += '{"value": "' + ticker['mappedTicker'] + '" ,"label" : "' + ticker['mappedTicker'] + '", "title" : "' + ticker['title'] +'", "link" : "' + ticker['link'] +'"},';
    }
    options = "[" + options.slice(0, -1)+ "]";
    setTickersState(JSON.parse(options));
    setTicker(JSON.parse(options)[0]['value']);
    setTitle(JSON.parse(options)[0]['title'])
    setLink(JSON.parse(options)[0]['link'])
    setShowAssets(true);
    setLoading(false);
  }
    }
  })
};

  const handleOrderType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrderType(event.target.value);
  };
  const handleNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ( parseFloat(event.target.value) <=0 || isNaN(parseFloat(event.target.value))){
    console.log("number bad");
    errorMessage("Price number to be a number above zero");
    setError(true);
    setDisableButton(true);
    }else{
	console.log("number ok");
        setError(false);
	    console.log(priceError);
        if(!priceError){ setDisableButton(false);}
    }
    setNumber(event.target.value);
  };

  const handlePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ( parseFloat(event.target.value) <=0 || isNaN(parseFloat(event.target.value))){
    console.log("price bad");
    errorMessage("Price needs to be a number above zero");
    setPriceError(true);
    setDisableButton(true);
    }else{
	console.log("price ok");
        setPriceError(false);
	    console.log(error);
	if(!error){ setDisableButton(false);}
    }
    setPrice(event.target.value);
  };

const order = async (payload) =>
 {
   fetch('/api/order', {
    method: 'POST',
    credentials: 'include',
    mode: "cors",
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  }
   )
    .then(res => {
            if(res.status === 200) {
            successMessage("order Complete");
            setDisableButton(true);
            }else{
            setSubmitted(false);
            setError("order fauked");
            }
  }).catch(response =>{
    setError("Order failed");
          setSubmitted('none')
  }
  );
 }

const handleSubmit = () => {
  setSubmitted(true);
  if( error === false && priceError === false){

    var payload = {
      email: email,
      order: orderType,
      ticker: ticker,
      price: parseFloat(price),
      volume: parseFloat(number),
    };
    if(currency != null)
{
console.log("we have currency to add");
      payload = {
      email: email,
      order: orderType,
      ticker: ticker,
      price: parseFloat(price) * parseFloat(currency),
      volume: parseFloat(number),
      currency: parseFloat(currency),
    };
}
    order(payload);
  }
  else
  {
    console.log("error message");
  }
};

  const successMessage = () => {
    return (
      <div
        className="success"
        style={{
          display: submitted ? '' : 'none',
        }}>
           <p>Order created. You should receive a confirmation email shortly.</p>
      </div>
    );
  };

const errorMessage = () => {
        return (
        <div
                className="error"
                style={{
                display: (error || priceError)? '' : 'none',
                }}>
                <p>Both order price and number of shares must be a number above zero</p>
        </div>
        );
};

if (isLoading) {
            return <div className="App">Loading...</div>;
          }
else{
  return (

<>
    <meta name="keywords" content="buy, sell, stocks, assets" />
    <meta name="description" content="Report a transaction" />
	  <ThemeProvider theme={darkTheme}>
{ influencer && <HeaderInfluencer navPosition="right" className="reveal-from-bottom" /> }
{ !influencer && <Header navPosition="right" className="reveal-from-bottom" /> }
<main className="site-content">
  <div style={{ paddingTop: '100px' }}>

  <Box
            sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            flexGrow: 4,
            borderRadius: 1,
            }}>

 <Box
            sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 1,
            m: 1,
            flexDirection: 'column',
            flexGrow: 4,
            borderRadius: 1,
            width: '95%'
            }}>
              <Box>
        <TextField
        sx={{ p: 1 }}
        id="standard-basic"
        label="Search Ticker"
        variant="standard"
        onChange={handleSearch}
      />
      
      <Box>
      {showAssets &&
        <TextField
        sx={{ p: 1 }}
          id="outlined-select-currency-native"
          select
          label="Asset"
          value={ticker}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
        >
          { tickersState.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
        }
        </Box>
        <Button onClick={handleLink} disabled={!showAssets}>
        {title}
        </Button>

        </Box>
        </Box>
        </Box>

        <Box
            sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 1,
            m: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            borderRadius: 1,
            }}>
              <Box>
        <TextField
        sx={{ p: 1 , width : '12ch'}}
          id="outlined-select-currency-native"
          select
          label="Order Type"
          value={orderType}
          onChange={handleOrderType}
          SelectProps={{
            native: true,
          }}
        >
          {orderTypes.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
	  ))}
        </TextField>
        </Box>

	  <TextField sx={{ p: 1, width : '40ch'}} id="outlined-basic" label={"Total price of all shares "+currencySymbol} variant="outlined" value={price} onChange={handlePrice} />
    <Box>
	  <TextField sx={{ p: 1 }} id="outlined-basic" label="Number of shares" variant="outlined" value={number} onChange={handleNumber} />
    </Box>
    </Box>
      <Box
            sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 1,
            m: 1,

            flexDirection: 'column',
            flexGrow: 4,
            borderRadius: 1,
            }}>
              <Box>
      <Button sx={{ p: 2 }} disabled={disableButton}
        onClick={() => {
          handleSubmit();
        }}
      >
        Submit Order
      </Button>
      </Box>
      <Box>
      <Button sx={{ p: 2 }}
        onClick={
        () => window.location = 'mailto:illbeatwallstreet@gmail.com?subject=help with my order'}

      >
        Ask for help
      </Button>
      </Box>
      </Box>
       <div className="success">{successMessage()}</div>
      <div className="error">{errorMessage()}</div>
      <div style={{ paddingTop: '50px' }}></div>
      { ownedAssets &&
    <TableContainer component={Paper} sx={{ p: 2 }}>
      <Table sx={{ p:1, minWidth: 200, borderSpacing: '0px 4px', borderCollapse: 'separate'}} aria-label="customized table">
        <TableHead>

          <TableRow                   style={{
                    height: 0,
                  }}>
            <TableCell align="center">Asset</TableCell>
            <TableCell align="center">Owned</TableCell>
            <TableCell align="center">Price Per Share</TableCell>
            <TableCell align="center">Average Buy</TableCell>
          </TableRow>
          </TableHead>
                  <TableBody>
          {ownedAssets.map((row) => (
          <>
            <TableRow key={row.asset}                   style={{
                    height: 0,
                  }}>
              <TableCell align="center">{row.asset}</TableCell>
              <TableCell align="center">{row.total}</TableCell>
              <TableCell align="center">{row.pricePerShare}</TableCell>
              <TableCell align="center">{row.averageBuy}</TableCell>
            </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    }
    </div>
</main>
	  </ThemeProvider>
</>

  );
}
}
export default OrderForm;




