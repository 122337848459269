import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TagsWidget from './TagsWidget';
import FormControl from '@mui/material/FormControl';

const NewsWidget = ({suggestedTags, setSelectedTags, selectedTags, MenuProps, tickersState, price, handlePrice,
                    number, handleNumber, link, handleLink, disableButton, handleSubmit, internalTags, setInternalTags}) => {
return(
  
<>
<h2 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">Give an update</h2>
      <Box
        sx={{
          '& .MuiTextField-root': { m: 0, p: 1, width: '95%', maxWidth : '40ch' },
          flexWrap: 'wrap'
        }}
        noValidate
        autoComplete="off"
        
     >
    <Box sx={{ m: 0, p: 1}}>
        <TagsWidget suggestedTags={suggestedTags} selectedTags={selectedTags} setSelectedTags={setSelectedTags} internalTags={internalTags} setInternalTags={setInternalTags}/>
    </Box>
       <div>
       </div>
	   <TextField multiline id="outlined-textarea" label="Title" variant="outlined" value={price} onChange={handlePrice} />
	   <TextField multiline id="outlined-textarea" label="Comment" variant="outlined" value={number} onChange={handleNumber} />
     <TextField multiline id="outlined-textarea" label="Link" variant="outlined" value={link} onChange={handleLink} />

     <div></div>
     <Button disabled={disableButton}
        onClick={() => {
          handleSubmit();
        }}
       >
       Submit News Update
     </Button>
    </Box>
    </>
);
}
export default NewsWidget;





