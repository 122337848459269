import * as React from 'react';
import  API from '../Api';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import SimpleImageUploader from './SimpleImageUploader';



const InfluencerEdit = ({influencerKey, displayName, biography}) => {

const [_displayName, setDisplayName] = React.useState(displayName);
const [_nameStatus, setNameStatus] = React.useState('');
const [_biography, setBiography] = React.useState(biography);
const [_displayPic, setDisplayPic] = React.useState(null);
const [_submittable, setSubmittable] = React.useState(true);

const updateInfluencer = () => {

  if(_displayPic == null){
    console.log("ignoring display pic");
  }else{
    console.log("do display pic update request");
const formData = new FormData();
formData.append('file', _displayPic);
formData.append('user', 'stu')

    const options = {
      method: 'POST',
      body: formData,
    };
  console.log(formData);  
    fetch('/api/influencer/displaypicture', options);   
  }
  var payload = {
        "description": _biography.replace(/\n/g, "\\n").replace(/\"/g, '\\"'),
        "displayName": _displayName.replace(/\n/g, "\\n").replace(/\"/g, '\\"'),}
        console.log("patch update for influencer text: " + payload);
        fetch('/api/influencer/editprofile', {
        method: 'POST',
        credentials: 'include',
        mode: "cors",
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }
        )
        .then(res => {
        setSubmittable(false);
      }).catch(response =>{
        console.log("failed");
        setSubmittable(true);
      }
      );

};

const handleImageUpdate = (image) => {
  setDisplayPic(image);
  console.log(image);
};


const handleDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  console.log(event.target.value);
  setDisplayName(event.target.value);
  if(event.target.value === ""){
    setNameStatus("username must not be empty");
    setSubmittable(false);
    return;
  }
  if(event.target.value === displayName){
    setNameStatus("No Change");
    setSubmittable(true);
    return;
  }

  API({
    url: '/user/displayName/' +event.target.value
  })
  .then(response => {
    if (response.statusText === "OK"){
      //setDisableButton(false);
      setNameStatus(event.target.value + " is all yours");
      setSubmittable(true);
    }}).catch(response =>{
      setNameStatus(event.target.value + " already taken");
      setSubmittable(false);
    });
};

const handleBiographyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
// we're not doing any validation on this
    setBiography(event.target.value);
};

return(
<>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
     >
      <>
      <SimpleImageUploader handleImageUpdate={handleImageUpdate}/>
      <i>*only accepting JPG images</i><br/>
              </>
       <TextField multiline id="outlined-textarea" label="Public user name" variant="outlined" value={_displayName} onChange={handleDisplayNameChange} />
       <div>
       <p>{_nameStatus}</p>
       </div>
	     <TextField multiline id="outlined-textarea" label="Biography and investment premises" variant="outlined" value={_biography} onChange={handleBiographyChange} />
     <div></div>
     <Button disabled={!_submittable}
        onClick={() => {
          updateInfluencer();
        }}
       >
       Update Profile
     </Button>
    </Box>
    </>
);
}
export default InfluencerEdit;

