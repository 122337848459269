import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';


const VoteIcon = ({vote, size}) => {

return(
<>
  {vote == "false" && <CancelIcon sx={{ fontSize: size, color: "red" }} />}
  {vote == "true" && <CheckCircleIcon sx={{ fontSize: size, color: "green" }}/>}
  {vote == "undetermined" && <HelpIcon sx={{ fontSize: size}}/>}
  {vote == "inconclusive" && <QuestionAnswerIcon sx={{ fontSize: size, color: "orange" }}/>}

</>
);

};
export default VoteIcon;
