import { useState } from 'react';
import  API from '../Api';
import Header from '../components/layout/Header';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ReactGA from "react-ga4";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { color } from 'highcharts';

export default function Form() {

ReactGA.initialize("G-WQP2CKSW84");
const [disableButton, setDisableButton] = useState(false);
// States for registration
const [name, setName] = useState('');
const [displayName, setDisplayName] = useState('');
const [email, setEmail] = useState('');
const [password, setPassword] = useState('');
const [currency, setCurrency] = useState('AUD');

// States for checking the errors
const [submitted, setSubmitted] = useState(false);
const [error, setError] = useState(false);

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const handleDisplayName = (event: React.ChangeEvent<HTMLInputElement>) => {
  console.log(event.target.value);
  if(event.target.value === ""){
    setDisplayName(event.target.value);
    setName(event.target.value);
    return;
  }
  API({
    url: '/user/displayName/' +event.target.value
  })
  .then(response => {
    if (response.statusText === "OK"){
      setDisableButton(false);
      setError(false);
      setSubmitted(false);
    }}).catch(response =>{
      setDisableButton(true);
      setError("User Name Taken");
    });
    setDisplayName(event.target.value);
    setName(event.target.value);
};

const register = async (event) =>
 {
   fetch('/api/user/register', {
    method: 'POST',
    credentials: 'include',
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify({
       'name' : name,
       'email' : email.toLowerCase(),
       'displayName' : displayName,
       'password' : password,
       'currency' : currency,
    })
  }
   )
    .then(res => {
	    if(res.status === 200) {
	    setSubmitted(true);
	    setDisableButton(true);
	    }else{
	    setSubmitted(false);
	    setError("Registration error");
	    }
  }).catch(response =>{
    setError("Registration failed");
	  setSubmitted('none')
  }
  );
 }

// Handling the name change
const handleName = (e) => {
	setName(e.target.value);
	setSubmitted(false);
};

// Handling the email change
const handleEmail = (e) => {
	setEmail(e.target.value);
	setSubmitted(false);
};

const handleCurrencyChange = (e) => {
	setCurrency(e.target.value);
	setSubmitted(false);
  console.log(e.target.value);
};

// Handling the password change
const handlePassword = (e) => {
	setPassword(e.target.value);
	setSubmitted(false);
};

// Handling the form submission
const handleSubmit = (e) => {
	e.preventDefault();
	if (name === '' || email === '' || password === '') {
	setError("Please enter values");
	} else if(!email.toLowerCase()
        .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ))
	{
        setError("enter valid email");
	}else if (password.length <8)
        {
          setError("Please make a longer password");
        }else{
	register();
		//setSubmitted(true);
	setError(false);
	}
};

  // Showing success message
  const successMessage = () => {
    return (
      <div
        className="success"
        style={{
          display: submitted ? '' : 'none',
        }}>
        <p>Thanks, {displayName}. You will recieve an email shortly to activate your account. Check your spam folder.</p>
      </div>
    );
  };

// Showing error message if error is true
const errorMessage = () => {
	return (
	<div
		className="error"
		style={{
		display: error ? '' : 'none',
		}}>
		<p>{error}</p>
	</div>
	);
};

  return (
	  <>
          <ThemeProvider theme={darkTheme}>
	      <Header navPosition="right" className="reveal-from-bottom" />
    <main className="site-content">
    <div style={{ paddingTop: '100px' }}>
    <div className="form">
    <div className="container-xs">
    <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
    Register Account
    </h1>
    <p style={{color: 'gray'}}>
    Outperform the market and prove it 
    </p>
    {/*<Image
     src={signup}
     alt="Our community debating ideas"
     width={500}
  height={400} />*/}
    <div style={{ paddingTop: '5px', margin: 'auto' }}/>
    <div className="container-lg">


<Box
      onSubmit={handleSubmit}
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 0, p: 1, width: '95%', maxWidth : '40ch' },
        flexWrap: 'wrap'
      }}
      noValidate
      autoComplete="off"
    >
<Box>
<TextField id="outlined-basic" label="User Name" variant="outlined" value={displayName} onChange={handleDisplayName} />
</Box>
<Box>
<TextField id="outlined-basic" label="Email" variant="outlined" value={email} onChange={handleEmail} />
</Box>
<Box>
<TextField id="outlined-basic" label="Password" type="password" variant="outlined" value={password} onChange={handlePassword} />
</Box>
<FormControl sx={{ m: 1, minWidth: 220 }}>
  <InputLabel id="demo-simple-select-label">Currency</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={currency}
    label="Curency"
    onChange={handleCurrencyChange}
  >
    <MenuItem value={'AUD'}>Australian Dollar</MenuItem>
    <MenuItem value={'USD'}>U.S Dollar</MenuItem>
    <MenuItem value={'EURO'}>Euro</MenuItem>
    <MenuItem value={'GBP'}>Pound sterling</MenuItem>
  </Select>
</FormControl>
<br/>
<p>
Check your junk mail folder for an email from <i>illbeatwallstreet@gmail.com</i>
</p>
<Button
disabled={disableButton}
onClick={handleSubmit}
>
Register Account
</Button>
<Button onClick={() => window.location = 'mailto:illbeatwallstreet@gmail.com?subject=help with my sign up'}>
Ask for help
</Button>
</Box>
      {/* Calling to the methods */}
      <div className="messages">
        {errorMessage()}
        {successMessage()}
      </div>
</div>
</div>
</div>
	  </div>
	  </main>
</ThemeProvider>
</>
  );
}



