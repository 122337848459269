import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb, faPenNib }from '@fortawesome/free-solid-svg-icons'


function verdictColour (verdict){
  if(verdict == "true"){
    return "green";
  }else if (verdict == "false"){
    return "red";
  }else if (verdict == "inconclusive"){
    return "orange";
  }else{
    return ""; // don't override styling
  }
}

const Headline = ({date, feed, name, headline, comment, link, tags, isNews, verdict}) => {
  if (comment.length > 200){
    comment = comment.substring(0,comment.substring(0,200).lastIndexOf(" "))+"..."
  }
  if ( headline != null ){
      if (headline.length > 200){
      headline = headline.substring(0,headline.substring(0,200).lastIndexOf(" "))+"..."
    }
  }
  let withoutProtocol = link.replace(/https?:\/\//i, "");
  let domain = withoutProtocol.substring(0, withoutProtocol.indexOf("/"));

  return (
<div className="tiles-item reveal-from-right" data-reveal-delay="200">
<div className="tiles-item-inner">
    {isNews && <FontAwesomeIcon icon={faLightbulb} size="lg" color={verdictColour(verdict)}/>}
    {!isNews && <FontAwesomeIcon icon={faPenNib} size="lg" color={verdictColour(verdict)}/>}
  <div className="testimonial-item-content">
    <p style={{whiteSpace: 'pre-line'}} className="text-sm mb-0">
      {comment}
        </p>
  </div>
  <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
    <span className="testimonial-item-name text-color-high"><a href={feed}>{headline}</a></span>
    {isNews && (link.length >0) && <span className="text-color-low" style={{fontSize:'25px', color:'white'}}> | </span> }
    <span className="testimonial-item-link">
    {isNews && (link.length >0) && <a href={link}>{domain}</a> }
    </span> 
  </div>
</div>
</div>
  )
}
export default Headline

