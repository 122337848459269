import { useState } from 'react';
import Header from '../components/layout/Header';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import queryString from "query-string"
import ReactGA from "react-ga4";
export default function Form() {
const navigate = useNavigate();
const queryParams = queryString.parse(window.location.search)
const userkey = queryParams['key'];
const [disableButton, setDisableButton] = useState(false);
// States for registration
const [key, setKey] = useState('');
const [password, setPassword] = useState('');
// States for checking the errors
const [submitted, setSubmitted] = useState(false);
const [error, setError] = useState(false);
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
ReactGA.initialize("G-WQP2CKSW84");
//ReactGA.send({ hitType: "pageview", page: "/resetpassword_page", title: "resetpassword_page" });

const register = async (event) =>
 {
console.log(userkey);
console.log(key);
console.log(password);
   fetch('/api/user/reset/follow_link/'+userkey, {
    method: 'POST',
    credentials: 'include',
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify({
       'password' : password,
       'key' : userkey
    })
  }
   )
    .then(res => {
	    if(res.status === 200) {
	    setSubmitted(true);
	    setDisableButton(true);
      navigate("/");
	    }else{
      console.log(userkey);
      console.log(password);
	    setSubmitted(false);
	    setError("Reset error");
	    }
  }).catch(response =>{
    setError("Reset failed");
	  setSubmitted('none')
  }
  );
 }

// Handling the password change
const handlePassword = (e) => {
  console.log(e.target.value);
	setPassword(e.target.value);
	setSubmitted(false);
};

// Handling the form submission
const handleSubmit = (e) => {
const queryParams = queryString.parse(window.location.search)
const userkey = queryParams['key'];
const userpassword = password;
setKey(userkey)

	e.preventDefault();
	if (password === '') {
	setError("Please enter a password");
	}else if (password.length <8)
        {
          setError("Please make a longer password");
        }else{
	register();
		setSubmitted(true);
	setError(false);
	}
};

// Showing error message if error is true
const errorMessage = () => {
	return (
	<div
		className="error"
		style={{
		display: error ? '' : 'none',
		}}>
		<p>{error}</p>
	</div>
	);
};

  return (
	  <>
          <ThemeProvider theme={darkTheme}>
	      <Header navPosition="right" className="reveal-from-bottom" />
    <main className="site-content">
    <div style={{ paddingTop: '100px' }}>
    <div className="form">
    <div className="container-xs">
    <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
    Enter New Password
    </h1>
    <div className="container-xs">


      {/* Calling to the methods */}
      <div className="messages">
        {errorMessage()}
      </div>

<Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
<TextField id="outlined-basic" label="Password" type="password" variant="outlined" value={password} onChange={handlePassword} />
<br/>
<Button
disabled={disableButton}
onClick={handleSubmit}
>
New Password
</Button>
</Box>
    </div>
</div>
</div>
	  </div>
	  </main>
</ThemeProvider>
</>
  );
}
