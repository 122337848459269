import * as React from 'react';
import  API from '../Api';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';



const AddYahooAsset = () => {

const [_ticker, setTicker] = React.useState('');
const [_title, setTitle] = React.useState('');
const [_search, setSearch] = React.useState('');
const [_submitted, setSubmitted] = React.useState(false);

const addTicker = () => {

  if(_ticker == "" || _title == "" || _search == ""){
    return;
  }else{
  var payload = {
        "ticker": _ticker,
        "title": _title,
        "search": _search
      }
        console.log("add new asset: ");
        console.log(payload);

        fetch('/api/ticker/add', {
        method: 'POST',
        credentials: 'include',
        mode: "cors",
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }
        )
        .then(res => {
        setSubmitted(true);
      }).catch(response =>{
        console.log("failed");
        setSubmitted(false);
      }
      );

}
};

const handleTickerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setTicker(event.target.value);
};

const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setTitle(event.target.value);
};

const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setSearch(event.target.value.toLowerCase());
};

return(
<>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '95%', maxWidth : '40ch' },
        }}
        noValidate
        autoComplete="off"
     >
       <TextField multiline id="outlined-textarea" label="Yahoo Ticker" variant="outlined" value={_ticker} onChange={handleTickerChange} />
	     <TextField multiline id="outlined-textarea" label="Title" variant="outlined" value={_title} onChange={handleTitleChange} />
       <TextField multiline id="outlined-textarea" label="Search" variant="outlined" value={_search} onChange={handleSearchChange} />
     <div></div>
     <Button disabled={_submitted}
        onClick={() => {
          addTicker();
        }}
       >
       Add US Asset
     </Button>
    </Box>
    </>
);
}
export default AddYahooAsset;



