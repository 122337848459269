
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import LandingPage from './LandingPage';
import React, { useEffect } from 'react';
import PagedHomePage from './sections/PagedHomePage';
import Header from '../components/layout/HeaderLoggedIn';
import HeaderInfluencer from '../components/layout/HeaderInfluencer';
import  API from '../Api';
import { isMobile } from 'react-device-detect';
const Chart = ({ tags, name, currency, loggedIn, influencer, dailySecret, histSecret}) => {

const [stocks, setStocks] = React.useState([
]);
const [dates, setDates] = React.useState([
]);


const [pointFormatWithCurrency, setPointFormatWithCurrency] = React.useState('<b style="color: {series.color}">⬤</b><b>{series.name}: ${point.y}</b><br>');
const [footerFormatWithCurrency, setFooterFormatWithCurrency] = React.useState('<b>Worth: ${point.total} </b>');
const [currencySymbol, setCurrencySymbol] = React.useState('$');

const basePortfolioURI = '/api/portfolio'
const mobileURI = '/mobile'
var portfolioURI = basePortfolioURI

const fetchStocks = async() => {
  if( isMobile ){
  portfolioURI += mobileURI;
  }
  const res = await fetch (portfolioURI, {
    method: 'GET',
    credentials: "same-origin"
    });
  const data = await res.json()
  return data
}
// get news based on user tags which should come in body from user info

function fetchUser(){
  const getStocks = async () => {
    const JsonFromServer = await fetchStocks()
    setStocks(JsonFromServer.seriesdataset)
    if(JsonFromServer.currency != null){
      for (let ticker = 0; ticker < JsonFromServer.seriesdataset.length; ticker++) {
        JsonFromServer.seriesdataset[ticker].data = JsonFromServer.seriesdataset[ticker].data.map((x, index) => Math.round(x / JsonFromServer.currency[index]* 100) / 100);
		  }
    }
    var dateStrings = JsonFromServer.dates
    var dates = []
    dateStrings.forEach(function(entry) {
    dates.push(Date.parse(entry)+39600000);
});
    setDates(dates);
    //setPortfolio(JsonFromServer.portfolio)
  }
  getStocks()

console.log(pointFormatWithCurrency);
if ( currency === "GBP"){
  console.log('<b style="color: {series.color}">⬤</b><b>{series.name}: £{point.y}</b><br>');
  setPointFormatWithCurrency('<b style="color: {series.color}">⬤</b><b>{series.name}: £{point.y}</b><br>');
  setFooterFormatWithCurrency('<b>Worth: £{point.total} </b>');
  setCurrencySymbol('£');
}else if ( currency === "EURO"){
  setPointFormatWithCurrency('<b style="color: {series.color}">⬤</b><b>{series.name}: €{point.y}</b><br>');
  setFooterFormatWithCurrency('<b>Worth: €{point.total} </b>');
  setCurrencySymbol('€');
}else if ( currency === "AUD" || currency === "USD"){
  setPointFormatWithCurrency('<b style="color: {series.color}">⬤</b><b>{series.name}: ${point.y}</b><br>');
  setFooterFormatWithCurrency('<b>Worth: ${point.total} </b>');
  setCurrencySymbol('$');
}
}

// duplicate function to get news, this needs to move elsewhere but lets get a poc
useEffect(() =>{
  fetchUser()
}, [])

const options = {
  symbols: ['circle'],
  credits: {
        enabled: false
    },
  series: stocks,
  chart: {
    type: 'area',
      zoomType: 'x',
      backgroundColor: '#151719',
      height: 600
    },
	    rangeSelector: {
      buttons: [{
        text: '+',
        events: {
          click() {
            return false
          }
        }
      }, {
        text: '-',
        events: {
          click() {
            return false
          }
        }

      }]
    },
    title: {
        text: 'My asset portfolio',
	    style: { "color": "#ececec", "fontSize": "18px" }
    },
    subtitle: {
        text: name+'\'s investments',
	    style: { "color": "#ececec"}
    },
    xAxis: {
       type: 'datetime',
       labels: {
         formatter: function() {
           const format = {
             second: '%Y-%m-%d',
             minute: '%Y-%m-%d',
             hour: '%Y-%m-%d',
             day: '%y%M-%d',
             week: '%Y%m-%d',
             month: '%Y-%m',
             year: '%Y'
           }[this.tickPositionInfo.unitName];
           return  Highcharts.dateFormat('%B-%d/%y', this.value);
         }
       },
        categories: dates,
        title: {
            enabled: false
        }
    },
    yAxis: {
        opposite: true,
        title: {
            text: currencySymbol+'1000'
        },
        labels: {
          formatter: function () {
              return this.value / 1000;
          }
        }
    },
    tooltip: {
      xDateFormat: '%d %B %Y',
      shared: true,
      split: false,
      pointFormat: pointFormatWithCurrency, //'<b style="color: {series.color}">⬤</b><b>{series.name}: currency{point.y}</b><br>',
      footerFormat: footerFormatWithCurrency, //'<b>Worth: {currency}{point.total} </b>',
    }
    ,
    plotOptions: {
        area: {
            stacking: 'normal',
            lineColor: '#DDDDDD',
            lineWidth: 1,
            }, 
        series: {
            marker: {
                enabled: false
            },
            animation: {
                duration: 3000
            }
        }
    }
}
function Mailto({ email, subject, body, ...props }) {
  return (
    <div style={{ paddingTop: '100px', fontSize: '35px', textAlign: "center"}}>
	  <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
      {props.children}
    </a>
	  </div>
  );
}
return (
	<>
{ influencer && <HeaderInfluencer navPosition="right" className="reveal-from-bottom" /> }
{ !influencer && <Header navPosition="right" className="reveal-from-bottom" /> }

	<main className="site-content">
	<div style={{ paddingTop: '100px' }}>
    <HighchartsReact
      containerProps={{ style: { height: "100%" } }}
    highcharts={Highcharts}
    options={options}
  />
<LandingPage dailySecret={dailySecret} histSecret={histSecret}/>
  <Mailto email="illbeatwallstreet@gmail.com" subject="portfolio query" body="">
    Ask for help :)
  </Mailto>
<PagedHomePage topDivider tags={tags} loggedIn={true}/>
</div>
</main>
	</>
  )
}

export default Chart
