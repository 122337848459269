import * as React from "react";
import { LineChart, Line, XAxis, YAxis, ReferenceLine, ResponsiveContainer } from "recharts";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ThemeProvider, createTheme } from '@mui/material/styles';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

class OrderModel extends React.Component {
  render() {
    return (
    <ThemeProvider theme={darkTheme}>
        <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow style={{height: 0}}>
            <TableCell align="center">Order Date</TableCell>
            {this.props.order.complete == true && <TableCell align="center">Date Sold</TableCell>}
            <TableCell align="center">Ticker</TableCell>
            <TableCell align="center">Asset at Purchase</TableCell>
            <TableCell align="center">Asset Now</TableCell>
          </TableRow>
          </TableHead>
          <>
          <TableBody>
            <TableRow key={this.props.order.date} style={{height: 0}}>
              <TableCell  align="center" component="th" scope="row">
                {this.props.order.date}
              </TableCell>
              {this.props.order.complete == true && <TableCell align="center">{this.props.order.dateSold}</TableCell>}
              <TableCell align="center">{this.props.order.ticker}</TableCell>
              <TableCell align="center">{this.props.order.assetBefore}</TableCell>
              <TableCell align="center">{this.props.order.assetNow}</TableCell>
            </TableRow>
            <TableRow style={{height: 0}}>
            <TableCell align="center">Purchase</TableCell>
            {this.props.order.complete == true && <TableCell align="center">Price Sold</TableCell>}
            <TableCell align="center">Value Now</TableCell>
            <TableCell align="center">Percentage Change</TableCell>
            <TableCell align="center">Percentage Of Asset</TableCell>
          </TableRow>
          <TableRow key={this.props.order.purchaseBefore} style={{height: 0}}>
              <TableCell align="center" component="th" scope="row">
                {this.props.order.purchaseBefore}
              </TableCell>
              {this.props.order.complete == true &&<TableCell align="center">{this.props.order.priceSold}</TableCell>}
              <TableCell align="center">{this.props.order.purchaseNow}</TableCell>
              <TableCell align="center">{this.props.order.percentageChanged}</TableCell>
              <TableCell align="center">{this.props.order.percentageOfPortfolio}</TableCell>
          </TableRow>
        </TableBody>
        </>
      </Table>
    </TableContainer>

<main className="site-content">
<div style={{ paddingTop: '20px' }}>
<div className="container-xs" >
<ResponsiveContainer width="100%" height={400}>
    <LineChart
      width={600}
      height={300}
      data={this.props.order.graphSeries}
      margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
    >
      <Line type="monotone" dataKey="value" stroke="#8884d8" dot={false} strokeWidth={3} />
      <XAxis dataKey="Month" />
      <YAxis width={77} />
      <ReferenceLine x={this.props.order.graphLine} label="" stroke="green" strokeWidth={2.5} />
      {this.props.order.complete == true && <ReferenceLine x={this.props.order.graphLineClosed} label="" stroke="red" strokeWidth={2} />}
    </LineChart>
    </ResponsiveContainer>
    </div>
    </div>
    </main>
        </>
        </ThemeProvider>
    )
  }
}
export default OrderModel

