import React, { useState } from "react";

const SimpleImageUploader = ({handleImageUpdate}) => {

  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <div>
      {selectedImage && (
        <div>
          <img
            alt="not found"
            width={"250px"}
            src={URL.createObjectURL(selectedImage)}
          />
          <br />
          <button onClick={() => { setSelectedImage(null); handleImageUpdate(null);}}>Remove</button>
        </div>
      )}

      <br />
      <br />
      
      <input
        type="file"
        name="myImage"
        onChange={(event) => {
          console.log(event.target.files[0]);
          setSelectedImage(event.target.files[0]);
          handleImageUpdate(event.target.files[0])
        }}
      />
    </div>
  );
};

export default SimpleImageUploader;