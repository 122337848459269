import * as React from "react";
import WordCloud from 'react-d3-cloud';
import { useEffect } from 'react';
import  API from '../../Api';

const WordCloudAllTags = ({
  userTags,
  ...props
}) => {

const [weightedTags, setWeightedTags] = React.useState([]);
useEffect(() => {
  API({
    url: '/tags/weights'
    })
    .then(response => {
var filteredArray = response.data['tags'];
for(var i = 0; i < userTags.length; i++){
  console.log("removing " + userTags[i]);
  filteredArray = filteredArray.filter(e => e.text !== userTags[i]);
}

for(var value = 0; filteredArray.length > 60 ; value ++){
  console.log("filtering down " + filteredArray.length);
  filteredArray = filteredArray.filter(e => e.value > value);
}
setWeightedTags(filteredArray)
    });

}, []);


const subscribe = (tag) => {
  userTags.push(tag);
  fetch('/api/user/updateTags', {
    method: 'POST',
    body: JSON.stringify({"tags": userTags}),
    credentials: 'include',
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*'
    }
  })
  .then(res => {
    if (res.status === 200) {
      var filteredArray = weightedTags.filter(e => e.text !== tag);
      setWeightedTags(filteredArray);
      window.location.reload();
    } else {
    }
  }).catch(response =>{
  });
}
//var filteredArray = [];
//for(var i; i < userTags.length; i++){
//  console.log("removing " + userTags[i]);
//  filteredArray = weightedTags.filter(e => e.text !== userTags[i]);
//  setWeightedTags(filteredArray);
//}



    return (
<WordCloud
    data={weightedTags}
    width={window.innerWidth}
    height={400}
    font="Times"
    fontStyle="normal"
    fontWeight="bold"
    fontSize={(word) => (Math.sqrt(word.value)*2.5)+15}
    spiral="rectangular"
    rotate={(word) => (0)}//((Math.random()*60)-30)}//word.value % 90)}
    padding={3}
    random={Math.random}
    fill={'white'}
    onWordClick={(event, d) => {
      subscribe(d.text);
      console.log(`sub: ${d.text}`);
    }}
  />
    )
  }
export default WordCloudAllTags 
