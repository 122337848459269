import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import TagsWidget from './TagsWidget';




const PredictionWidget = ({suggestedTags, setSelectedTags, selectedTags, MenuProps, tickersState,
                    disableButton, handleSubmitPrediction, prediction, year, month,
                    handlePrediction, handleYear, handleMonth, internalTags, setInternalTags}) => {

return(
<>
<LocalizationProvider dateAdapter={AdapterDayjs}>
<h2 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">Make a prediction</h2>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 2, p: 1, width: '95%', maxWidth: '100ch' },
          flexWrap: 'wrap'
        }}
        noValidate
        autoComplete="off"
     >
         <div>
    <Box sx={{ m: 0, p: 1}}>
        <TagsWidget suggestedTags={suggestedTags} selectedTags={selectedTags} setSelectedTags={setSelectedTags}  internalTags={internalTags} setInternalTags={setInternalTags}/>
    </Box>
    </div>
       <div>
       </div>
	   <TextField multiline id="outlined-textarea" label="Prediction" variant="outlined" value={prediction} onChange={handlePrediction} />
       <DemoItem label={'Predicted by'}/>
       <DateCalendar views={['year', 'month']} openTo="month" onChange={(newDate) => {handleMonth(("0" + (newDate.month() + 1)).slice(-2)); handleYear(newDate.year()) }} />


     <div></div>
     <Button disabled={disableButton}
        onClick={() => {
          handleSubmitPrediction();
        }}
       >
       Submit Prediction
     </Button>
    </Box>
    </LocalizationProvider>
    </>
);
}
export default PredictionWidget;




