


const YearBadgeWidget = ({year, netPercentage, percentile}) => {

    function toHex(number){
        var rounded = Math.round(number);
        if(rounded < 10){
            return "0" + rounded;
        }else{
            return rounded.toString(16);
        }
    }

    function calculateColor(percentage){
        if (percentage < -50){
            return "#c80000";
        }else if (percentage < 0 && percentage >= -50){
            return "#c8" + toHex(200+(percentage*2)) + toHex(220+(percentage*2));
        }else if (percentage == 0){
            return "#c8c8c8";
        }else if (percentage > 0 && percentage <= 50){
            return "#" + toHex(200-(percentage*2)) + "c8" + toHex(220-(percentage*2));
        }else if (percentage > 50){
            return "#00c800";
        }

    }

    var colour = calculateColor(netPercentage);
    var circleStyle = null;
    if (year == "Per Year"){
    circleStyle = {
        padding:10,
        margin:3,
        display:"inline-block",
        backgroundColor:colour,
        borderRadius: "50%",
        width:90,
        height:120
    };
    }else{
        circleStyle = {
        padding:10,
        margin:2,
        display:"inline-block",
        backgroundColor:colour,
        borderRadius: "50%",
        width:70,
        height:100
    };
    }

    var yearStyle = {
        fontWeight: 900,
        fontSize:15,
        padding:0,
        margin:0,
        textAlign: 'center',
        color: 'black',
    };
    var topStyle = {
        paddingTop:1,
        marginTop:0,
        marginBottom:0,
        textAlign: 'center',
        color: 'black',
    };
    var bottomStyle = {
        textAlign: 'center',
        color: 'black',
        marginTop:'-3px',
    };


    return(
        <>
        <div style={circleStyle}>
            <p style={topStyle}>{netPercentage}%</p>
            <p style={yearStyle}>{year}</p>
            <p style={bottomStyle}>{percentile}p<sup>th</sup></p>
        </div>
        </>
    );

};
export default YearBadgeWidget;
