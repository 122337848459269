import * as React from 'react';
import Testimonials from '../sections/PagedFeed';
import Box from '@mui/material/Box';

const InfluencerDisplay = ({influencer, news, predictions, updateNews, updatePredictions}) => {

return(
<>
<Box
  sx={{
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    p: 1,
    m: 1,
    bgcolor: 'background.paper',
    borderRadius: 1,
  }}>
      <Testimonials topDivider={false} influencer={influencer} news={news} predictions={predictions} updateNews={updateNews} updatePredictions={updatePredictions}/>
</Box>
</>
);
}
export default InfluencerDisplay; 
