import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Headline from './Headline'
import  API from '../../Api';
import InfiniteScroll from 'react-infinite-scroller';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const PagedHomePage = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  loggedIn,
  tags,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Ideas that we\'re following',
    paragraph: 'Log in for more insight'
  };
  const sectionHeaderLoggedIn = {
    title: 'Ideas that you\'re following',
    paragraph: 'specific to your investment preferences'
  };

var page  = 0;
const [hasMoreToLoad, setHasMoreToLoad] = React.useState(true);
const [allIdeas, setAllIdeas] = React.useState([]);



const fetchIdeas = async (page) => {
  setHasMoreToLoad(false);
  var newIdeas = allIdeas;
    console.log("fetching " + page);
        API({
      url: '/predictions/page/'+ page
      })
      .then(response => {
        newIdeas = newIdeas.concat(response.data);
        API({
          url: '/news/page/'+ page 
          })
          .then(response => {
            newIdeas = newIdeas.concat(response.data);
            let sorted = newIdeas.sort(compareByDate);
            setAllIdeas(sorted,
              setHasMoreToLoad(page<100))
            }
          )
          }
        );
};

function compareByDate( a, b ) {
  if ( a.date > b.date ){
    return -1;
  }
  if ( a.date < b.date ){
    return 1;
  }
  return 0;
}

function parseMonth(month){
  if(month === "01"){
    return "January";
  }
  if(month === "02"){
    return "Feburary";
  }
  if(month === "03"){
    return "march";
  }
  if(month === "04"){
    return "April";
  }
  if(month === "05"){
    return "May";
  }
  if(month === "06"){
    return "June";
  }
  if(month === "07"){
    return "July";
  }
  if(month === "08"){
    return "August";
  }
  if(month === "09"){
    return "September";
  }
  if(month === "10"){
    return "October";
  }
  if(month === "11"){
    return "November";
  }
  if(month === "12"){
    return "December";
  }
}

const ideasPadding = [
{
key: 0,
date: "2024/05/09 15:01:22",
feed: null,
headline: "████████████",
comment: "████████████",
link: "/",
tags: ['all']},
{key: 1,
  date: "2024/05/09 15:01:22",
feed: null,
comment: "████████████",
link: "/",
headline: "████████████",
tags: ['all']},
];


 function renderIdea(idea, index) {
  var isNews = false;
  if(idea['verdict'] == null){
  isNews = true;
  // this is news
  var date = idea['date'];
  var feed = 'https://illbeatwallstreet.com/feed?news='+ idea['newsId'];
  var comment = idea["comment"];
  var link = idea['link'];
  var headline = idea['title'];
  var tags = idea['tags'];
  }else{
  // prediction
  var date = idea['date'];
  var feed = 'https://illbeatwallstreet.com/feed?prediction='+ idea['predictionId'];
  var comment = idea["prediction"];
	var link = 'https://illbeatwallstreet.com/feed?influencer='+ idea['influencerKey'];
  var headline = "Verdict in " +parseMonth(idea['month'])+ " " + idea['year'];
  var tags = idea['tags'];
  var verdict = idea['verdict'];
  }
  return (<Headline key={index} date={date} feed={feed} comment={comment} headline={headline} link={link} tags={tags} isNews={isNews} verdict={verdict}/>)
}
  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
{ false &&
 <div className="container-lg">  
</div>
}
<InfiniteScroll
  pageStart={page}
  loadMore={fetchIdeas}
  hasMore={hasMoreToLoad}>
  {<div className={tilesClasses}>{allIdeas.length > 0 ? allIdeas.map((x, index) => renderIdea(x, index)) : ideasPadding.map((x, index) => renderIdea(x, index))}</div>}
</InfiniteScroll>
        </div>
      </div>
    </section>
  );
}

PagedHomePage.propTypes = propTypes;
PagedHomePage.defaultProps = defaultProps;

export default PagedHomePage;

