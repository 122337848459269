import * as React from 'react';
import  API from '../Api';
import Header from '../components/layout/HeaderLoggedIn';
import HeaderInfluencer from '../components/layout/HeaderInfluencer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import NewsWidget from '../components/elements/NewsWidget';
import PredictionWidget from '../components/elements/PredictionWidget';
import AddYahooAsset from '../components/elements/AddYahooAsset';
import { useNavigate } from 'react-router';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});



const NewsForm = ({email, influencer, influencerKey}) => {
  const [ticker, setTicker] = React.useState('AAPL');
  const [tickersState, setTickersState] = React.useState('');
  const [orderType, setOrderType] = React.useState('buy');
  const [number, setNumber] = React.useState('');
  const [link, setLink] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [submitted, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [priceError, setPriceError] = React.useState(false);
  const [disableButton, setDisableButton] = React.useState(true);
  const [isLoading, setLoading] = React.useState(true);
  const [isPrediction, setIsPrediction] = React.useState(false);
  const [isNews, setIsNews] = React.useState(true);
  const [assets, setAssets] = React.useState('');
  const [tags, setTags] = React.useState('');
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [internalTags, setInternalTags] = React.useState([]);
  const [monthly, setMonthly] = React.useState('');
  const [annual, setAnnual] = React.useState('');
  const [newsAccepted, setNewsAccepted] = React.useState(false);
  const [prediction, setPrediction] = React.useState('');
  const [year, setYear] = React.useState('');
  const [month, setMonth] = React.useState('');
  const navigate = useNavigate();

  const handleTagChange = (event: ChangeEvent<HTMLInputElement>) => {
   console.log(event.target.value);
   setTicker(event.target.value);
  };

  const handleMultiTagChange = (event: ChangeEvent<HTMLInputElement>) => {
   console.log(event.target.value.split(','));
   setSelectedTags(event.target.value.split(','));
  };

  const handleAnnualChange = (event: ChangeEvent<HTMLInputElement>) => {
   console.log(event.target.value);
   setAnnual(event.target.value);
  };

  const handleMonthlyChange = (event: ChangeEvent<HTMLInputElement>) => {
   console.log(event.target.value);
   setMonthly(event.target.value);
  };
  const handlePrediction = (event: ChangeEvent<HTMLInputElement>) => {
   console.log(event.target.value);
   if(event.target.value.length > 0 && isPrediction){
    setDisableButton(false);
   }else{
    setDisableButton(true);
   }
   setPrediction(event.target.value);
  };
  const handleYear = (a) => {
   console.log(a);
   setYear(a);
  };
  const handleMonth = (a) => {
   console.log(a);
   setMonth(a);
  };

const getTags = () => {
  setLoading(true);
    API({
    url: '/tags/weights'
  })
  .then(response => {
    if (response.statusText === "OK"){
    
    console.log("received tags");
    let initialTags = response.data['tags'];
    let filtered = initialTags.reduce(
      (array, currentValue) => array.concat(currentValue['text']),
      [],
    );
    setTags(filtered);
    console.log(filtered);
    }
  }).catch(response =>{
  })
  setLoading(false);
  };

  const getNewsLetter = () => {
    setLoading(true);
      API({
      url: '/news/newsletter'
    })
    .then(response => {
      if (response.statusText === "OK"){
        console.log(response.data[0]['monthly']);
        console.log(response.data[0]['annual']);
        setMonthly(response.data[0]['monthly'])
        setAnnual(response.data[0]['annual'])
      }
    }).catch(response =>{
    })
    setLoading(false);
    };

React.useEffect(() => {
  if(isLoading === true){
    getTags();
    getNewsLetter();
    console.log("annual: " + annual);
    console.log("monthly: " + monthly);
    setLoading(false);
    }
    });

  const handleNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumber(event.target.value);
  };

  const handleLink = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLink(event.target.value);
  };

  const handlePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value.length > 0 && isNews){
      setDisableButton(false);
     }else{
      setDisableButton(true);
     }
    setPrice(event.target.value);
  };

const order = async (payload) =>
 {
   fetch('/api/news/update', {
    method: 'POST',
    credentials: 'include',
    mode: "cors",
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  }
   )
    .then(res => {
            setPrice('');
            setNumber('');
            setLink('');
            if(res.status === 200) {
            // reset news form
            setPrice('');
            setNumber('');
            setLink('');
            setInternalTags([]);
            successMessage("News Updated");
            setDisableButton(true);
            }else{
            setSubmitted(false);
            setError("order fauked");
            }
  }).catch(response =>{
    setError("Order failed");
          setSubmitted('none')
  }
  );
 }

 const submitPrediction = async (payload) =>
 {
   fetch('/api/predictions/makeprediction', {
    method: 'POST',
    credentials: 'include',
    mode: "cors",
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  }
   )
    .then(res => {
            setPrediction('');
            setMonth('');
            setSelectedTags([]);
            if(res.status === 200) {
            setPrediction('');
            setMonth('');
            setInternalTags([]);
            successMessage("Prediction Confirmed");
            setDisableButton(true);
            }else{
            setSubmitted(false);
            setError("prediction fauked");
            }
  }).catch(response =>{
    setError("prediction failed");
          setSubmitted('none')
  }
  );
 }

 const newsLetter = async (payload) =>
  {
    fetch('/api/news/newsletter', {
     method: 'PUT',
     credentials: 'include',
     mode: "cors",
     headers: {
       'Access-Control-Allow-Origin': '*',
       'Content-Type': 'application/json'
     },
     body: JSON.stringify(payload)
   }
    )
     .then(res => {
             if(res.status === 200) {
               successMessage("Updated");
               setNewsAccepted(true);
             }else{
               successMessage("Failed");
               setNewsAccepted(false);
             }
   }).catch(response =>{
     setError("Order failed");
     setNewsAccepted(false)
   }
   );
  }

const handleSubmit = () => {
  setSubmitted(true);
  if( error === false && priceError === false){
    var payload = {
      tags : selectedTags,
      title : price.replace(/\n/g, "\\n").replace(/\"/g, '\\"').replace(/\t/g, ' '),
      comment : number.replace(/\n/g, "\\n").replace(/\"/g, '\\"').replace(/\t/g, ' '),
      link : link
    };
    console.log(payload);
    order(payload);
  }
  else
  {
    console.log("error message");
  }
};

const handleSubmitPrediction = () => {
  setSubmitted(true);
  if( error === false && priceError === false){
    var payload = {
      tags : selectedTags,
      prediction : prediction.replace(/\n/g, "\\n").replace(/\"/g, '\\"').replace(/\t/g, ' '),
      year : year,
      month : month
    };
    console.log(payload);
    submitPrediction(payload);
  }
  else
  {
    console.log("error message");
  }
};

const switchToNews = () => {
    setIsPrediction(false);
    setIsNews(true);
    setDisableButton(false);
    setSubmitted(false);
};
const switchToPrediction = () => {
    setIsPrediction(true);
    setIsNews(false);
    setDisableButton(false);
    setSubmitted(false);
};

const handleNewsLetterSubmit = () => {
    var payload = {
      annual : annual.replace(/\n/g, "\\n").replace(/\"/g, '\\"'),
      monthly : monthly.replace(/\n/g, "\\n").replace(/\"/g, '\\"'),
    };
    console.log(payload);
    newsLetter(payload);
};

  const successMessage = () => {
    return (
      <div
        className="success"
        style={{
          display: submitted ? '' : 'none',
        }}>
           <p>updated.</p>
      </div>
    );
  };

const errorMessage = () => {
        return (
        <div
                className="error"
                style={{
                display: (error || priceError)? '' : 'none',
                }}>
                <p>Title or Prediction must be set</p>
        </div>
        );
};

if (isLoading) {
            return <div className="App">Loading...</div>;
          }
else{
  return (
<>
    <meta name="keywords" content="news, updates, predictions, influencer, ideas" />
    <meta name="description" content="Share ideas with others" />
  <ThemeProvider theme={darkTheme}>
{ influencer && <HeaderInfluencer navPosition="right" className="reveal-from-bottom" /> }
{ !influencer && <Header navPosition="right" className="reveal-from-bottom" /> }
  <main className="site-content">
  <div style={{ paddingTop: '100px' ,paddingRight: '100px' }}>
    <div className="container-lg">
         <Button disabled={isNews}
        onClick={() => {
          switchToNews();}}
       >
       Give an update
     </Button>
     <Button disabled={isPrediction}
        onClick={() => {
          switchToPrediction();}}
       >
       Make a prediction
     </Button>
     <Button
     onClick={() =>{
      navigate("/feed?influencer="+influencerKey);
     }}>go to feed
     </Button>
     {isNews && !isPrediction && <NewsWidget suggestedTags={tags} setSelectedTags={setSelectedTags} selectedTags={selectedTags}
      MenuProps={MenuProps} tickersState={tickersState} price={price} handlePrice={handlePrice}
                    number={number} handleNumber={handleNumber} link={link}
                    handleLink={handleLink} disableButton={disableButton} handleSubmit={handleSubmit} internalTags={internalTags} setInternalTags={setInternalTags}
                    />}
     {!isNews && isPrediction &&
        <PredictionWidget disableButton={disableButton} suggestedTags={tags} setSelectedTags={setSelectedTags} selectedTags={selectedTags}  internalTags={internalTags} setInternalTags={setInternalTags}
      MenuProps={MenuProps} tickersState={tickersState} prediction={prediction} year={year} month={month}
      handleSubmitPrediction={handleSubmitPrediction} handlePrediction={handlePrediction}
      handleYear={handleYear} handleMonth={handleMonth} />
     }

    {email == "stumay1992@gmail.com" &&
    <>
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '95%', maxWidth : '40ch' },
        flexWrap: 'wrap'
      }}
      noValidate
      autoComplete="off"
   >
   <TextField multiline id="outlined-textarea" label="Yearly Comment" variant="outlined" value={annual} onChange={handleAnnualChange} />
   <TextField multiline id="outlined-textarea" label="Monthly Comment" variant="outlined" value={monthly} onChange={handleMonthlyChange} />
   <br/>
   <Button disabled={newsAccepted}
      onClick={() => {
        handleNewsLetterSubmit();
      }}
     >
     Submit News Letter Update
   </Button>
  </Box>
  <Box>
    <AddYahooAsset />
  </Box>
   </>
   }
    <div className="success">{successMessage()}</div>
    <div className="error">{errorMessage()}</div>
    </div>
  </div>
<div style={{ paddingTop: '100px' ,paddingRight: '100px' }}></div>
</main>
</ThemeProvider>
</>

  );
}
}
export default NewsForm;




