import React, { useEffect, useState } from 'react';
// import sections
import Header from '../components/layout/HeaderLoggedIn';
import HeaderInfluencer from '../components/layout/HeaderInfluencer';
import WordCloudUserTags from '../components/sections/WordCloudUserTags';
import WordCloudAllTags from '../components/sections/WordCloudAllTags';

import ReactGA from "react-ga4";
import  API from '../Api';
import {View, Image, StyleSheet} from 'react-native';
import Box from '@mui/material/Box';
import InfluencerEdit from '../components/elements/InfluencerEdit';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const styles = StyleSheet.create({
  profile: {
    width: 300,
    height: 300,
    borderRadius: 40,
  },
});

const Admin = ({name, tags, loggedIn, influencer, influencerKey}) => {


  useEffect(() => {

    if (influencerKey !== undefined)
    {
    console.log("asking for influencer");
        API({
        url: '/influencer/'+influencerKey
        })
        .then(response => {
        if (response.statusText === "OK"){
        setInfluencerData(response.data);
        }
        }).catch(response =>{
        });
    }  
  }, []);

  const [files, setFiles] = React.useState([]);
  const [influencerData, setInfluencerData] = React.useState(undefined);
  const updateFiles = (incommingFiles) => {
    //do something with the files
    console.log("incomming files", incommingFiles);
    setFiles(incommingFiles);
    //even your own upload implementation
  };
  const removeFile = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };


  const handleStart = (filesToUpload) => {
    console.log("advanced demo start upload", filesToUpload);
  };

ReactGA.initialize("G-WQP2CKSW84");
console.log("influencer is " + influencerKey);
console.log(influencerData);
  return (
    <>
    <meta name="keywords" content="subscriptions, tags, profile" />
    <meta name="description" content="User settings" />
    <ThemeProvider theme={darkTheme}>
{ influencer && <HeaderInfluencer navPosition="right" className="reveal-from-bottom" /> }
{ !influencer && <Header navPosition="right" className="reveal-from-bottom" />}
    <main className="site-content">
      <div style={{ paddingTop: '100px' }}/>
      <div className="container-md"></div>
      <Box
            sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 1,
            m: 1,
            flexDirection: 'column',
            flexGrow: 4,
            borderRadius: 1,
            }}>
              { influencer && <h2>Influencer Profile</h2> }
        <Box
          sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              p: 1,
              m: 1,
              flexDirection: 'row',
              flexWrap: 'wrap',
              flexShrink: 1,
              flexGrow: 4,
              borderRadius: 1,
         }}>
          { influencer && (influencerData !== undefined) && 
                <Image
                style={styles.profile}
                source={
                  'https://illbeatwallstreet.com/profiles/' + influencerData['profilePicture']
                }
              />
          }
          </Box>
          { influencerData && <InfluencerEdit influencerKey={influencer} displayName={influencerData.displayName} biography={influencerData.description}/>}
          <h2 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">Tags you're subscribed to</h2>
          <WordCloudUserTags tags={tags} />
          <h2 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">Popular tags available</h2>
          <WordCloudAllTags userTags={tags} />
          </Box>
	  </main>
    </ThemeProvider>
    </>
  );
}

export default Admin;


