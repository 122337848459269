import { WithContext as ReactTags } from 'react-tag-input';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import * as React from 'react';

const TagsWidget = ({suggestedTags, selectedTags, setSelectedTags, internalTags, setInternalTags}) => {
  const [tagSearch, setTagSearch] = React.useState('');
  const [suggestedTagsFiltered, setSuggestedTagsFiltered] = React.useState([]);

  const updateTagSearch = (event: ChangeEvent<HTMLInputElement>) => {
      let tagString = event.target.value.toLowerCase();


      //handle tag searches
      let matchArray = []
      matchArray = (suggestedTags.filter(element => element.includes(tagString)));
      // filter out current elements
      matchArray = matchArray.filter(element => (internalTags.includes(element) == false));
      matchArray = matchArray.filter(item => item !== "all");
      if(matchArray.length <=5){
      setSuggestedTagsFiltered(matchArray);
      }else{
        matchArray = matchArray.filter(item => (item.length /2) < tagString.length);
        setSuggestedTagsFiltered(matchArray);
      }


      // handle tag creation
      if(tagString.length >0 && tagString.split('\n').length >1 || tagString.split(',').length >1 ){
        if(tagString.substr(0,tagString.length-1).length <1){
          return;
        }
        let tag = tagString.substr(0,tagString.length-1).trim();
        if(internalTags.includes(tag) == false){
        var tags = [...internalTags, tag];
        setInternalTags(tags);
        setSelectedTags(tags);
        }

        //handleAddition(tag);
        setTagSearch("");
        return;
      }else{
        setTagSearch(event.target.value);
      }
  }

  function renderTags (tag, index) {
    return (<Chip key={"chip"+index} label={tag} variant="outlined" onDelete={() =>handleDelete(index)}/>);
    }

  function renderSuggestions (tag, index) {
    return(
      <Box><Button
       key={"suggestion"+index}
       onClick={() => {
        var tags = [...internalTags, tag];
        setInternalTags(tags);
        setSelectedTags(tags);
        setTagSearch("");
        setSuggestedTagsFiltered([]);
      }}
      >{tag}</Button></Box>);
  }
    function handleDelete(i) {
        setInternalTags(internalTags.filter((tag, index) => index !== i));
      };
      
    return(
        <>
        <TextField multiline id="outlined-textarea" label="Tags for searching" variant="standard" value={tagSearch} onChange={updateTagSearch} />
        <Box
        sx={{
          flexWrap: 'wrap',
          flexDirection: 'row',
          alignItems: 'flex-start',
        }}  
     >
        {suggestedTagsFiltered.map((x, index) => renderSuggestions(x, index))}
        </Box>
        <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
        {internalTags.map((x, index) => renderTags(x, index))}

        </Stack>
        </>
    );
};
export default TagsWidget;

