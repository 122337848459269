import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Headline from './Headline'
const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const PredictionsSummary = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  predictions,
  loggedIn,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Ideas that we\'re following',
    paragraph: 'Log in for more insight'
  };
  const sectionHeaderLoggedIn = {
    title: 'Ideas that you\'re following',
    paragraph: 'specific to your investment preferences'
  };

function compareByDate( a, b ) {
  if ( a.date > b.date ){
    return -1;
  }
  if ( a.date < b.date ){
    return 1;
  }
  return 0;
}

function compareByFloat(a, b) {
  var totalA = 0;
  var totalB = 0;
  if (a.verdict == "true")
    totalA += 4;
  if (b.verdict == "true")
    totalB += 4;
  if (a.verdict == "false")
    totalA += 3;
  if (b.verdict == "false")
    totalB += 3;
  if (a.verdict == "inconclusive")
    totalA += 2;
  if (b.verdict == "inconclusive")
    totalB += 2;
  if (a.verdict == "undetermined")
    totalA += 1;
  if (b.verdict == "undetermined")
    totalB += 1;
  totalA += (a.date > b.date) * 0.2
  totalB += (b.date > a.date) * 0.2

  if (totalA < totalB){
    return 1;
  }else{
    return -1;
  }
}

// true false inconclusive undetermined
function compareByVerdict( a, b ) {
    if ( a.verdict == "true" && b.verdict == "false" ){
      return 1;
    }else if ( a.verdict == "false" && b.verdict == "inconclusive" ){
      return 1;
    }else if ( a.verdict == "inconclusive" && b.verdict == "undetermined" ){
      return 1;
    }else if ( a.date < b.date ){
      return 1;
    }else{
      return -1;
  }
}

function parseMonth(month){
  if(month === "01"){
    return "January";
  }
  if(month === "02"){
    return "Feburary";
  }
  if(month === "03"){
    return "march";
  }
  if(month === "04"){
    return "April";
  }
  if(month === "05"){
    return "May";
  }
  if(month === "06"){
    return "June";
  }
  if(month === "07"){
    return "July";
  }
  if(month === "08"){
    return "August";
  }
  if(month === "09"){
    return "September";
  }
  if(month === "10"){
    return "October";
  }
  if(month === "11"){
    return "November";
  }
  if(month === "12"){
    return "December";
  }
}

function renderIdea(idea) {
    var date = idea['date'];
    var feed = 'https://illbeatwallstreet.com/feed?prediction='+ idea['predictionId'];
    var comment = idea["prediction"];
      var link = 'https://illbeatwallstreet.com/feed?influencer='+ idea['influencerKey'];
    var headline = "Verdict in " +parseMonth(idea['month'])+ " " + idea['year'];
    var tags = idea['tags'];
    var verdict = idea['verdict'];
    return (<Headline date={date} feed={feed} comment={comment} headline={headline} link={link} tags={tags} isNews={false} verdict={verdict}/>)
}

var allIdeas = [];
for (const element of predictions) {
  console.log(element);
  allIdeas.push(element);
}
console.log(allIdeas);
allIdeas.sort(compareByFloat);
console.log(allIdeas);

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className={tilesClasses}>
{allIdeas.map(renderIdea)}
          </div>
        </div>
      </div>
    </section>
  );
}

PredictionsSummary.propTypes = propTypes;
PredictionsSummary.defaultProps = defaultProps;

export default PredictionsSummary;
