import * as React from "react";
import WordCloud from 'react-d3-cloud';

const WordCloudUserTags = ({
  tags,
  ...props
}) => {

const unsubscribe = (tag) => {
  tags = tags.filter(e => e !== tag);
  fetch('/api/user/updateTags', {
    method: 'POST',
    body: JSON.stringify({"tags": tags}),
    credentials: 'include',
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*'
    }
  })
  .then(res => {
    if (res.status === 200) {
    console.log("reload");
    window.location.reload();
    } else {
    }
  }).catch(response =>{
  });
}





console.log("tags to populate " + tags);
var data = tags.map((x) => ({'text': x, 'value': 1}));
console.log("data is " + data[0]);
    return (
<WordCloud
    data={data}
    width={window.innerWidth}
    height={200}
    font="Times"
    fontStyle="normal"
    fontWeight="bold"
    fontSize={25}
    spiral="rectangular"
    rotate={(word) => (0)}//((Math.random()*60)-30)}//word.value % 90)}
    padding={3}
    random={Math.random}
    fill={'yellow'}
    onWordClick={(event, d) => {
      unsubscribe(d.text);
      console.log(`unsub: ${d.text}`);
    }}
  />
    )
  }
export default WordCloudUserTags 

